const environment = {
    websiteApiBaseUrl: process.env.VUE_APP_API_BASE_URL + 'website/',
    websiteApiBaseUrlStaging: process.env.VUE_APP_API_BASE_URL_STAGING + 'website/',
    assetsDir: '/assets/images/website'
}
export {
    environment
}
export default {
    install(Vue) {
        Vue.websiteEnvironment = environment
        Vue.prototype.$websiteEnvironment = environment
    }
}
