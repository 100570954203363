import { UserService } from "../../services/user";
import * as types from "./mutations-types";

const userService = new UserService();

export default {
  setUser({ commit }, user) {
    // TODO - Ask backend for set this feature..
    // localStorage.setItem('user-prays', JSON.stringify(user))
    commit(types.SET_USER, user);
  },
  async getAnalyitcs({ dispatch, commit, rootState }, { keys, env }) {
    try {
      dispatch("sharedStore/setLoaderStatus", true, { root: true });

      const res = await userService.fetchAnalytics(keys, env);
      commit(types.SET_ANALYTICS, res);
    } catch (err) {
      this._vm.$swal({
        icon: "warning",
        text: "خطء غير متوقع",
        confirmButtonText: "حسناَ"
      });
    } finally {
      dispatch("sharedStore/setLoaderStatus", false, { root: true });
    }
  },
  async sendPraies({ dispatch, commit, rootState }, { numberOfPraies, env }) {
    try {
      if (numberOfPraies < 100) {
        this._vm.$swal({
          icon: "warning",
          text:
            "عندما تصل الى 100 يمكنك ادخال المشاركه بانتظار تفاعلك معنا ^_^",
          confirmButtonText: "حسناَ"
        });
        return;
      }

      if (numberOfPraies > 1000000000) {
        this._vm.$swal({
          icon: "warning",
          text: "يجب إدخال رقم صحيح",
          confirmButtonText: "حسناَ"
        });
        return;
      }

      const status = await this._vm.$swal({
        icon: "info",
        title: `هل أنت متأكد من إدخال رقم : ${numberOfPraies}`,
        confirmButtonText: "نعم, متأكد",
        showCancelButton: true,
        cancelButtonText: `تراجع`
      });

      if (!status.isConfirmed) return;

      const config = [...rootState.userStore.praiesKeys];
      config[1].value = +(numberOfPraies || 0);

      const payload = {
        analytics: config,
        mobile: rootState.userStore.userLoggedin.mobile
      };

      dispatch("sharedStore/setLoaderStatus", true, { root: true });
      const res = await userService.sendPraies(payload, env);

      this._vm.$swal({
        icon: "success",
        title: "من فوائد الصلاة على النبي",
        text: res.message,
        confirmButtonText: "حسناَ"
      });

      commit(types.SEND_PRAYS, numberOfPraies);
      return Promise.resolve("success");
    } catch (error) {
      this._vm.$swal({
        icon: "warning",
        text: "خطء غير متوقع",
        confirmButtonText: "حسناَ"
      });
      return Promise.reject();
    } finally {
      dispatch("sharedStore/setLoaderStatus", false, { root: true });
    }
  }
};
