<template>
  <dynamic-master-layout>
      <WebsiteFaqs></WebsiteFaqs>
  </dynamic-master-layout>
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import WebsiteFaqs from '@/app/website/website-components/website-faqs/website-faqs'
  import * as WebsiteAPIService from "../services";

  export default {
    name: 'Faqs',
    components: {
      DynamicMasterLayout,
      WebsiteFaqs
    },
    data: function () {
      return {
      }
    },
  }
</script>

<style scoped>

</style>
