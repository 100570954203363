<template>
  <div class="our-blog blog-filer mb-150">
    <div id="theme-banner-four">
      <img :src="$websiteEnvironment.assetsDir + '/website-svgs/news/' + 'Group-1574.svg'" style="margin-top: 100px; width: 1000px" 
            alt="" class="screen-one wow fadeInRight animated" data-wow-duration="2s">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg'" alt="" class="shape-one">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg'" alt="" class="shape-two">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg'" alt="" class="shape-three">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg'" alt="" class="shape-four">
      <div class="round-shape-one"></div>
      <div class="round-shape-two"></div>
      <div class="round-shape-three"></div>
      <div class="round-shape-four"></div>
      <div class="container">
        <div class="main-wrapper text-center">
          <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
               أخبار<b > التميّز</b >
          </h1>
          <h3 class="wow fadeInUp animated mt-150" data-wow-delay="0.4s">
              <u>  أخبارنا و آخر الفعاليات والمستجدات </u>
          </h3>
        </div>
      </div> 
    </div>
    
    
  <div class="container w-50 text-center">
    <div class="isotop-menu-wrapper  blog-filter-nav clearfix col">
      <a class="mx-5 my-3" :class="[filterOption==='isNews' ? 'is-checked' : '']" @click="filter('isNews')"><span>الكل</span></a>
      <a class="mx-5 my-3" :class="[filterOption==='isEvent' ? 'is-checked' : '']" @click="filter('isEvent')"><span>الأخبار</span></a>
      <a class="mx-5 my-3" :class="[filterOption==='isEvent' ? 'is-checked' : '']" @click="filter('isEvent')"><span>الفعاليات</span></a>
    </div>
  </div>

  <div class="our-blog blog-masonry">
    <div class="container">
      <div class="row masnory-blog-wrapper">
        <div class="isotop-item col-lg-4" v-for="(item, index) in news">
          <div class="single-blog-post">
            <div class="img-holder">
              <img  :src="$websiteEnvironment.assetsDir + '/website-svgs/news/test.jpg'" alt="">
            </div>
            <div class="post-data">
              <h5 class="blog-title-one title">
                <a href="blog-details.html"> {{item.title}} </a>
              </h5>
              <p class="blog-title-tow title">
                <a href="blog-details.html"> {{item.description}} </a>
              </p>
              <a href="blog-details.html" class="read-more"><i class="flaticon-next-1"></i></a>
            </div> 
          </div> 
        </div>
      </div>
      <div class="text-center pt-90">
      </div>
    </div>
  </div>


  <div class="element-section mb-150">
    <div class="container">
      <div class="pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center">
        <span v-on:click="paginate(pagination.previous)" class="theme-pager prev mx-4">
          <span class="flaticon-next mx-2"></span> &nbsp;&nbsp;السابق
        </span>
        <span v-on:click="paginate(pagination.next)" class="theme-pager next mx-4">التالي &nbsp;&nbsp;
          <span class="flaticon-back mx-2"></span>
        </span>
      </div>
    </div>
  </div>

  </div> <!-- /.our-blog -->
</template>

<script>
  import * as WebsiteAPIService from "../../services";
  import {VueMasonryPlugin} from 'vue-masonry';
  import Vue from 'vue'
  import isotope from 'vueisotope'

  Vue.use(VueMasonryPlugin)
  export default {
    name: 'website-news',
    components: {
      isotope,
    },
    data() {
      return {
        news: [
          {
            image : '',
            title : 'ورشة المهارات الدراسية',
            description : 'يوت اينايم أد مينيما فينيام كيواس نوستريوم أكسيركايتاشيم يلامكوربوريس سيوسكايبيت لابورايوسام'
          },
          {
            image : '',
            title : 'يوم الطفل العالمي',
             description : 'يوت اينايم أد مينيما فينيام كيواس نوستريوم أكسيركايتاشيم يلامكوربوريس سيوسكايبيت لابورايوسام'
          },
          {
            image : '',
            title : 'الدورة البرمجية للأطفال',
             description : 'يوت اينايم أد مينيما فينيام كيواس نوستريوم أكسيركايتاشيم يلامكوربوريس سيوسكايبيت لابورايوسام'
          }
        ],
        tags: [],
        pagination: {
          next: null,
          previous: null
        },
        filterOption: '*',
        option: {
          itemSelector: ".element-item",
          getFilterData: {
            isNews: function (itemElem) {
              return itemElem.type === "News";
            },
            isEvent: function (itemElem) {
              return itemElem.type === "Event";
            },
          }
        }
      }
    },
    created() {
      WebsiteAPIService.fetchBlogByType('News|Event', true).then((data) => {
        this.news = data['blogs']['data']
        this.pagination.next = data['blogs']['pagination']['next_page_url']
        this.pagination.previous = data['blogs']['pagination']['prev_page_url']
      })
    },
    methods: {
      paginate: function (url) {
        if (url != null) {
          WebsiteAPIService.paginate(url).then((data) => {
            this.news = data['blogs']['data']
            this.pagination.next = data['blogs']['pagination']['next_page_url']
            this.pagination.previous = data['blogs']['pagination']['prev_page_url']
          })
        }
      },
      filter: function (key) {
        this.$refs.cpt.filter(key);
        this.filterOption = key
      },
    },
  }
</script>

<style scoped>

#theme-banner-four .screen-one {
    position: absolute;
    right: -14%;
    top: 12%;
    z-index: 1;
    width: 45% !important;
}

</style>
