import DonateHome from './donate-views/DonateHome.vue'
import MasterLayout from "../../layouts/MasterLayout";

const donateRoutes = [
  {
    path: '/donate',
    name: 'DonateHome',
    component: DonateHome,
    meta: { layout: MasterLayout }
  }
]

export default donateRoutes
