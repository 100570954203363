<template>
    <WebsiteErrorPage></WebsiteErrorPage>
</template>

<script>
  import WebsiteErrorPage from '@/app/shared/components/errors/204'

  export default {
    name: 'Details',
    components: {
      WebsiteErrorPage
    },
  }


</script>

<style scoped>

</style>
