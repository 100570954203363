<template>
    <div id="theme-banner-four">
      <!-- <img :src="$donateEnvironment.assetsDir + '/donate-banner/img/donate.png'" style="margin-top: 100px; width: 1000px" alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s"> -->
      <img :src="$websiteEnvironment.assetsDir + '/website-svgs/success-storeis/' + 'Group-903.svg'"
             style="margin-top: 100px; width: 1000px" 
            alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg'" alt="" class="shape-one">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg'" alt="" class="shape-two">
      <!-- <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg'" alt="" class="shape-three"> -->
      <!-- <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg'" alt="" class="shape-four"> -->
      <div class="round-shape-one"></div>
      <!-- <div class="round-shape-two"></div> -->
      <!-- <div class="round-shape-three"></div> -->
      <!-- <div class="round-shape-four"></div> -->
      <div class="container">
        <div class="main-wrapper">
          <h2 class="wow fadeInUp animated h3" data-wow-delay="0.4s">
            قصص نجاح متميزة
            من داخل أسرة <b > التميّز</b >
       
          </h2>
          <p class="sub-title wow fadeInUp animated mt-5" data-wow-delay="0.9s">
            تسليط الضوء على التجارب التي نجحت في تحدي <br>
            الظروف والمصاعب للوصول إلى النجاحات الباهرة المتميزة
            </p>
        </div> <!-- /.main-wrapper -->
      </div> <!-- /.container -->
    </div> <!-- /#theme-banner-four -->
  </template>
  
  <script>
    export default {
        name: 'website-success-stories-banner',
    }
  </script>
  
  <style scoped>
  
  #theme-banner-four .screen-one {
      position: absolute;
      top: 12%;
      z-index: 1;
      width: 40% !important;
  }
  
  </style>
  