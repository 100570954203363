<template>
  <dynamic-master-layout>
    <WebsiteNews></WebsiteNews>
  </dynamic-master-layout>
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import WebsiteNews from '@/app/website/website-components/website-news/website-news'
  import * as WebsiteAPIService from '../services'

  export default {
    name: 'News',
    components: {
      DynamicMasterLayout,
      WebsiteNews
    },
  }
</script>

<style scoped>

</style>
