const StringCaseStyles = {
  normalCase: function (string) {
    return string.replace(/-/g, ' ')
  },
  kebabCase: function (string) {
    return (string) ? string.replace(/ /g, '-') : "";
  }
}

export {
  StringCaseStyles
}
export default {
  install(Vue) {
    Vue.stringCase = StringCaseStyles
    Vue.prototype.$stringCase = StringCaseStyles
  }
}

