import * as types from './mutations-types';

export default {
    /**
     * 
     * @param {*} state 
     * @param {*} status
     */
    [types.SET_LOADER_STATUS](state, status) {
        state.loaderStatus = status;
    }
}