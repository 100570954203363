<template>
  <!--
			=============================================
				Our Service Standard
			==============================================
		-->
  <div class="our-service service-standard">
    <div class="container">
      <div class="theme-title-three text-center mb-80">
        <h2 class="title">برامج العطاء</h2>
        <p>يفتحُ بوابَةَ العطاءِ أيادٍ معطاء .. تسعى لتحققَّ أحلاماً صغيرة .. لكنها في عيونهم كبيرة ..</p>
      </div> <!-- /.theme-title-three -->

      <div class="clearfix">
        <div class="row">

          <div class="col-lg-6 col-sm-12 p-4" v-for="(item, key) in programsList">
            <div class="single-counter-box">
              <img class="m-auto" :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/'+ item.img">
              <span class="centered">
                <h4 class="text-white text-nowrap">
                  {{item.title}}
                </h4>
                <p class="mb-0 mt-5 text-white">{{item.description}}</p>
              </span>
            </div> 
          </div>

          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/2.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج عيد الأضحى</a></h6>
                <p>يعمل التميز خلال أيام عيد الأضحى على مشروع الأضاحي حيث يقدّمُ اللحومَ لكافةِ أسرهِ</p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div> 
            </div> 
          </div>  -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/3.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج الكساء</a></h6>
                <p>يسعى قسمُ الكساءِ ضمن جمعية التميز جاهداً لأخذِ مكانِهِ المتميزِ في مقاعدِ العطاء.</p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div> 
            </div> 
          </div>  -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/4.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج إفطار صائم</a></h6>
                <p>يحتلُّ مشروعُ إفطارِ الصائمِ في شهرِ رمضانَ المبارك مساحةً واسعةً من عطائه، لتشملَ وجباتِ إفطارِ الصائمِ والموائدَ الرمضانيّة</p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div> 
            </div>
          </div>  -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/5.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج الميتم</a></h6>
                <p>يرعى التميّز أطفالَه الأيتامَ ممَن هم عند ذويهم فإنّه أيضاً يضمُ أيتامَ الميتمِ الداخلي ويوفرُ لهم
                  المأوى والرعاياتِ بمختلف أشكالِها الصحيةَ و النفسيةَ والتعليميةَ </p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div> 
            </div> 
          </div> -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/6.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج السلل الغذائية</a></h6>
                <p>يقدم التميز لاسره سلة غذائية بشهر رمضان المبارك</p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div>
            </div>
          </div>  -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/7.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج الصحي</a></h6>
                <p>يقومُ القسمُ الصحيُّ بالمتابعةِ الصحيّةِ الدوريةِ لأسرهِ وأطفاله، وتقديمِ المساعداتِ الطبيّةِ ابتداءً
                  من عياداتِه ذات الاختصاصاتِ المختلفة ومخابر الأشعة والتحاليل والصيدلية وصولاً إلى الإحالاتِ الطبيةِ
                  إلى المشافي لإجراء العمليات الجراحية </p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div>
            </div> 
          </div>  -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/8.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج تحقيق حلم</a></h6>
                <p>يسعى التميز لتنفيذِ طلباتِ أطفالِه وتحقيقِ أحلامِهم عن طريقِ رصدِ احتياجاتِهم وإيصالِها للكفلاءِ
                  .</p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div>
            </div> 
          </div>  -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/9.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج عيد الفطر</a></h6>
                <p>ينتظرُ التميز عيدَ الفطرِ بفارغِ الصبرِ ليقدّمَ العيادي والهدايا فيكونُ " معنى العيد معنا "</p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div> 
            </div> 
          </div>  -->
          <!-- <div class="col-lg-4 col-md-6">
            <div class="service-block" data-aos="fade-up" style="background-image: url(assets/images/donate/donate-programs/1.jpg);">
              <div class="hover-content">
                <h6 class="title"><a href="#">برنامج التبرعات العينية</a></h6>
                <p>يسعى التميز لتأمينِ الاحتياجات الأساسيّة من سلاتٍ تموينية وأصنافٍ عديدةٍ من المواد الغذائية </p>
                <a href="#" class="read-more"><i class="flaticon-back-1"></i></a>
              </div> 
            </div> 
          </div>  -->
        </div> <!-- /.row -->
      </div> <!-- /.clearfix -->
    </div> <!-- /.container -->
  </div> <!-- /.our-service -->

</template>
<script>
  export default {
    name: 'donate-programs',
    data () {
      return {
        programsList : [
          {
            img : 'Group-1566.svg',
            title : 'برنامج عيد الأضحى',
            description : 'متابعة أحوال الأسر خلال مقابلات دورية وإشراف لتطوير وضعهم'
          },
          {
            img : 'Group-1567.svg',
            title : 'برنامج الميتم',
            description : 'كما يرعى التميّز أطفالَه الأيتامَ  ويوفرُ لهم المأوى والرعاياتِ بمختلف أشكالِها ..'
          },
          {
            img : 'Group-1568.svg',
            title : 'برنامج عيد الفطر',
            description : 'كما يقوم في عيد الأضحى بمشروع الأضاحي حيث يقدّمُ اللحومَ لكافةِ أسرهِ'
          },
          {
            img : 'Group-1569.svg',
            title : 'برنامج إفطار صائم',
            description : 'يحتلُّ مشروعُ إفطارِ الصائمِ في شهرِ رمضانَ المبارك مساحةً واسعةً من عطائه، لتشملَ وجباتِ إفطارِ الصائمِ والموائدَ الرمضانيّة'
          },
          {
            img : 'Group-1570.svg',
            title : 'برنامج التبرعات العينية',
            description : 'يسعى التميز لتأمينِ الاحتياجات الأساسيّة من سلاتٍ تموينية وأصنافٍ عديدةٍ من المواد الغذائية والعينية .'
          },
          {
            img : 'Group-1571.svg',
            title : 'برنامج الكساء',
            description : 'يسعى قسمُ الكساءِ جاهداً لأخذِ مكانِهِ المتميزِ في مقاعدِ العطاء.. '
          },
        ]
      }
    },
  }
</script>

<style scoped>

/* .centered {
  position: absolute;
  top: 25%;
  left: 75%;
  transform: translate(-50%, -50%);
} */

/* should be optimized for small and very small screeens */
.centered {
  position: absolute;
  top: 50%;
  left: 67%;
  transform: translate(-50%, -50%);
}

p {
    font-size: 20px;
}

@media (max-width: 576px) { 
  h4 {
    font-size: 20px !important;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
  }

  .pt-5, .py-5 {
    padding-top: 1rem!important;
  }

  .mt-5, .my-5 {
    margin-top: 1rem!important;
  }

  p {
    font-size: 13px;
  }

 }

</style>
