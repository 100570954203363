import * as types from "./mutations-types";

export default {
  [types.SEND_PRAYS](state, numberOfPraies) {
    const _hijri_date_text = new Intl.DateTimeFormat("ar-TN-u-ca-islamic", {
      year: "numeric"
    }).format(Date.now());
    const _hijri_date_number = _hijri_date_text.match(/^\d+/)[0];

    const pureUserScore = +(!!state.userLoggedin?.score
      ? state.userLoggedin?.score
      : 0);
    const pureNumberOfPraies = +(!!numberOfPraies ? numberOfPraies : 0);
    const userScore = pureUserScore + pureNumberOfPraies;
    const pureAllPrayers = +(!!state.analytics?.rehab_al_habeb_prayers
      ? state.analytics?.rehab_al_habeb_prayers
      : 0);
    const allPrayers = pureAllPrayers + pureNumberOfPraies;

    const detailsKey = `year_${_hijri_date_number}`;
    const currentYear = _hijri_date_number + "";

    const analyticsUserScore = +(state.userLoggedin.details[detailsKey] || 0);
    state.userLoggedin.details[detailsKey] =
      analyticsUserScore + pureNumberOfPraies;

    const analyticsUserPrayers = +(
      state.analytics[`rehab_al_habeb_prayers_${currentYear}`] || 0
    );
    state.analytics[`rehab_al_habeb_prayers_${currentYear}`] =
      analyticsUserPrayers + pureNumberOfPraies;

    const analyticsUserVisitors = +(
      state.analytics[`rehab_al_habeb_visitors_${currentYear}`] || 0
    );
    state.analytics[`rehab_al_habeb_visitors_${currentYear}`] =
      analyticsUserVisitors + 1;

    state.userLoggedin.score = userScore;
    state.analytics.rehab_al_habeb_prayers = allPrayers;
  },
  [types.SET_USER](state, user) {
    state.userLoggedin = user;
  },
  [types.SET_ANALYTICS](state, data) {
    state.analytics = data;
  }
};
