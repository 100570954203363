<template>
  <div>
    <div id="theme-banner-one">
      <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
             <img :src="$websiteEnvironment.assetsDir + '/website-svgs/questions-and-answers/' + 'Group-1573.svg'" 
                  alt="" class="wow fadeInDown animated">
      </div>
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg'" alt="" class="oval-one">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg'" alt="" class="shape-three">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg'" alt="" class="shape-four">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg'" alt="" class="shape-five">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg'" alt="" class="shape-six">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg'" alt="" class="shape-seven">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg'" alt="" class="shape-eight">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg'" alt="" class="shape-nine">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg'" alt="" class="shape-ten">
      <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg'" alt="" class="shape-eleven">
      <div class="container">
        <div class="main-wrapper">
          <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">سؤال وجواب</h1>
          <h4 class="wow fadeInUp animated title mt-5" data-wow-delay="0.9s">
            <u> لديك سؤال ؟ قد تجد إجابتك هنا</u>            
          </h4>
        </div> 

        <div class="row text-center">
          <div class="col">
              <a href="contact-us-agency.html" class="contact-button line-button-one mx-3 my-3">التطوع</a>
              <a href="contact-us-agency.html" class="contact-button line-button-two mx-3 my-3">عام</a>
              <a href="contact-us-agency.html" class="contact-button line-button-one mx-3 my-3">الكفالة</a>
              <a href="contact-us-agency.html" class="contact-button line-button-two mx-3 my-3">الإستشارات</a>
              <a href="contact-us-agency.html" class="contact-button line-button-one mx-3 my-3">العمل</a>
              <a href="contact-us-agency.html" class="contact-button line-button-two mx-3 my-3">التدريس</a>
              <a href="contact-us-agency.html" class="contact-button line-button-one mx-3 my-3">التبرع</a>
              <a href="contact-us-agency.html" class="contact-button line-button-two mx-3 my-3">الأيتام</a>
              <a href="contact-us-agency.html" class="contact-button line-button-one mx-3 my-3">الدورات</a>
              <a href="contact-us-agency.html" class="contact-button line-button-two mx-3 my-3">الأرامل</a>
          </div>
        </div>

        <div class="row mt-150 mb-5">
          <div class="col-lg-6">
            <h4 class="my-5 py-4">ما هي خدمات جمعية التميّز ؟</h4>
            <h4 class="my-5 py-4">كيف يمكن تقديم التبرعات للجميعة ؟</h4>
            <h4 class="mt-5 mb-3 py-4">كيف يمكنني الإنضمام للجمعية ؟</h4>
            <p>يمكنك زيارة بناء الجمعية وتعبئة طلب تطوع أو توظيف</p>
          </div>
          <div class="col-lg-6">
            <h4 class="my-5 py-4">كيفية تسجيل يتيم لديكم ؟</h4>
            <h4 class="mt-5 mb-3 py-4">ما متطلبات كفالة يتيم ؟</h4>
            <p>بعض الأوراق الثبوتية الخاصة بالكافل
              و مايتعلق بها من مستلزمات
              </p>
          </div>
        </div>

        <div class="element-section mb-150">
          <div class="container">
            <div class="pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center">
              <span v-on:click="paginate(pagination.previous)" class="theme-pager prev mx-4">
                <span class="flaticon-next mx-2"></span> &nbsp;&nbsp;السابق
              </span>
              <span v-on:click="paginate(pagination.next)" class="theme-pager next mx-4">التالي &nbsp;&nbsp;
                <span class="flaticon-back mx-2"></span>
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <h2>أو , يمكنك  سؤالنا  مباشرةً</h2>
        </div>
        <!-- <h4 class="pt-5 ">مواد تدريبية تمتلك القدرة على إعطائها </h4>   -->
        <div class="row mt-5">
            <div class="col">
                <div class="contact-form">
                    <form class="form form-style-two" id="contact-form" data-toggle="validator" novalidate="true">
                        <div class="messages"></div>
                            <div class="controls row">
                              <div class="col-lg-6">  <div class="form-group has-error has-danger mb-5">
                                <input type="text" name="fullName" placeholder="الاسم*" required="required" 
                                        data-error="رجاءّ قم بادخال اسمك">
                                <div class="help-block with-errors">
                                    <ul class="list-unstyled">
                                        <!-- <li>Valid email is required.</li> -->
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group has-error has-danger mb-5">
                                <input type="text" name="age" placeholder="البريد الإلكتروني*" required="required" 
                                        data-error="رجاءّ قم بادخال عمرك">
                                <div class="help-block with-errors">
                                    <ul class="list-unstyled">
                                        <!-- <li>Valid email is required.</li> -->
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group has-error has-danger mb-5">
                                <input type="number" name="age" placeholder="رقم الموبايل*" required="required" 
                                        data-error="رجاءّ قم بادخال عمرك">
                                <div class="help-block with-errors">
                                    <ul class="list-unstyled">
                                        <!-- <li>Valid email is required.</li> -->
                                    </ul>
                                </div>
                            </div>
                          </div>

                          <div class="col-lg-6">
                              <div class="form-group has-error has-danger px-5">
                                  <textarea type="number" name="age" placeholder="السؤال*" required="required" 
                                          data-error="رجاءّ قم بادخال عمرك"></textarea>
                                  <div class="help-block with-errors">
                                      <ul class="list-unstyled">
                                          <!-- <li>Valid email is required.</li> -->
                                      </ul>
                                  </div>
                              </div>
                          </div>

                        </div>
                    </form>
                </div>
            </div>
            <div class="container">
              <div class="main-wrapper py-5 text-center">
                <a href="about-us-standard.html" class="more-button solid-button-one wow fadeInLeft  animated" 
                    data-wow-delay="0.5s" style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;">
                        تسجيل       
                </a>
              </div> 
            </div>
           
        </div>
      </div> 
    </div>

  </div>
</template>
<script>
  import * as WebsiteAPIService from "../../services";

  export default {
    name: 'website-faqs',
    data() {
      return {
        tab: 0,
        faqs: [],
        tags: [],
      }
    },
    method: {
      active: function (el) {
        this.tab = el
      }
    },
    created() {
      WebsiteAPIService.fetchBlogByType('FAQ').then((data) => {
        this.faqs = data['blogs'];
        this.tags = data['tags'];
      });
    },
    mounted() {
    }
  }
</script>

<style scoped>

#theme-banner-one .illustration {
    position: absolute;
    left: -10%;
    top: 0%;
    width: 40%;
}

</style>
