<template>
  <div>
    <div class="theme-title-one text-center hide-pr show-pr mt-5">
      <h2 class="main-title">طرق التبرع<br>
        <span class="h4">اختر الطريقة الأنسب إليك</span>
      </h2>
    </div>
    <div class="seo-work-progress">
      <div class="container">

        <div class="row single-work-list">
          <div class="col-lg-6">
            <div class="text-wrapper">
              <div class="icon-box">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1242.svg'" alt="">
              </div>
              <h2 class="title">الجابي</h2>
              <ul>
                <li>لوريم ايبسوم دولار سيت أميت كونسيكتيتور</li>
                <li>أليكيوب أكس أيا كوممودو كونسيكيو</li>
                <li>كيواي راتاشن سيكيواي</li>
              </ul>
              <a href="/contact-us" class="contact-button line-button-one">المزيد</a>
            </div> <!-- /.text-wrapper -->
          </div>
          <div class="col-lg-6">
            <div class="img-box clearfix">
              <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/'+ 'Group-786.svg'" alt="">
            </div> <!-- /.img-box -->
          </div>
        </div>

        <div class="row single-work-list">
          <div class="col-lg-6">
            <div class="img-box clearfix">
              <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/'+ 'Group-785.svg'" alt="">
            </div> <!-- /.img-box -->
          </div>
          <div class="col-lg-6">
            <div class="text-wrapper">
              <div class="icon-box">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1243.svg'" alt="">
              </div>
              <h2 class="title">البنك</h2>
              <ul>
                <li>لوريم ايبسوم دولار سيت أميت كونسيكتيتور</li>
                <li>أليكيوب أكس أيا كوممودو كونسيكيو</li>
                <li>كيواي راتاشن سيكيواي</li>
              </ul>
              <a href="/contact-us" class="contact-button line-button-one">المزيد</a>
            </div> <!-- /.text-wrapper -->
          </div>
        </div>

        <div class="row single-work-list">
          <div class="col-lg-6">
            <div class="text-wrapper">
              <div class="icon-box">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1244.svg'" alt="">
              </div>
              <h2 class="title"> رسائل ال SMS</h2>
              <ul>
                <h2 class="title">قريباً</h2>
              </ul>
            </div> <!-- /.text-wrapper -->
          </div>
          <div class="col-lg-6">
            <div class="img-box clearfix">
              <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/'+ 'Group-1564.svg'" alt="">
            </div> <!-- /.img-box -->
          </div>
        </div>

       
      </div> <!-- /.container -->
    </div> <!-- /.seo-work-progress -->
  </div>
</template>
<script>
  export default {
    name: 'donate-ways'
  }
</script>

<style scoped>

.seo-work-progress .single-work-list .text-wrapper .title {
    font-size: 48px;
    line-height: 60px;
    padding-bottom: 28px;
    /* text-decoration: none; */
}

</style>
