<template>
  <div>
    <div class="solid-inner-banner mt-0 pt-5">
      <h2 class="page-title">من نجاحاتنا</h2>
    </div> 
  
    <div class="our-blog blog-masonry">
      <div class="container">
        <div class="row masnory-blog-wrapper">
          <div class="isotop-item" v-for="(story, index) in stories">
            <div class="single-blog-post">
              <div class="img-holder">
                <img  :src="$websiteEnvironment.assetsDir + '/website-svgs/news/test.jpg'" alt="">
              </div>
              <div class="post-data">
                <h5 class="blog-title-one title">
                  <a href="blog-details.html"> {{story.title}} </a>
                </h5>
                <p class="blog-title-tow title">
                  <a href="blog-details.html"> {{story.description}} </a>
                </p>
                <a href="blog-details.html" class="read-more"><i class="flaticon-next-1"></i></a>
              </div> 
            </div> 
          </div>
        </div>
        <div class="text-center pt-90">
        </div>
      </div>
    </div>

    <div class="element-section mb-150">
      <div class="container">
        <div class="pd-footer d-md-flex justify-content-between align-items-center pt-15 text-center">
          <span v-on:click="paginate(pagination.previous)" class="theme-pager prev mx-4">
            <span class="flaticon-next mx-2"></span> &nbsp;&nbsp;السابق
          </span>
          <span v-on:click="paginate(pagination.next)" class="theme-pager next mx-4">التالي &nbsp;&nbsp;
            <span class="flaticon-back mx-2"></span>
          </span>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  import * as WebsiteAPIService from '../../services'
  import { VueMasonryPlugin } from 'vue-masonry'
  import Vue from 'vue'

  Vue.use(VueMasonryPlugin)

  export default {
    name: 'website-success-stories',
    components: {},
    data () {
      return {
        stories: [
          {
            image_url : 'test.jpg',
            title : 'نجاحات دراسية',
            description :'كيواس نوستريوم أكسيركايتاشي يلامكوربوريس سيوسكايبي لابورايوسام'
          },
          {
            image_url : 'test.jpg',
            title : 'بطولة دمشق',
            description :'يلامكوربوريس سيوسكايبي يواس نوستريوم أكسيركايتاشيم لابورايوسام'
          },
          {
            image_url : 'test.jpg',
            title : 'نجاحات عملية',
            description :'كيواس نوستريو سيوسكايبيت لابورايوسام أكسيركايتاشيم'
          }               
        ],
        pagination: {
          next: null,
          previous: null
        },
      }
    },
    created () {
      WebsiteAPIService.fetchBlogByType('Story', true).then((data) => {
        this.stories = data['blogs']['data']
        this.pagination.next = data['blogs']['pagination']['next_page_url']
        this.pagination.previous = data['blogs']['pagination']['prev_page_url']
      })
    },
    mounted () {
    },
    methods: {
      paginate: function (url) {
        if (url != null) {
          WebsiteAPIService.paginate(url).then((data) => {
            this.stories = data['blogs']['data']
            this.pagination.next = data['blogs']['pagination']['next_page_url']
            this.pagination.previous = data['blogs']['pagination']['prev_page_url']
          })
        }
      }
    }
  }
</script>

<style scoped>

</style>
