import { AuthService } from '@/app/services/auth/auth.service';
import { isValid, rules } from '@/app/shared/utilities/validations.util';
import router from '@/app/app-routes';

const authService = new AuthService();

export default {
    async login({dispatch, commit, rootState}, { phone, env }) {
        try {
            await dispatch('sharedStore/setLoaderStatus', true, { root: true })   
            
            if(!isValid(phone, rules.phone)) throw new Error('error')

            const res = await authService.login(phone, env)
            await dispatch('userStore/setUser', res, { root: true })
            
            await router.push({ path: env == 'prod' ? '/' : '/atroja' })
        }
        catch(err) {
            this._vm.$swal({
                icon: 'warning',
                text: 'غير مصرّح لك بالدخول ',
                confirmButtonText: 'حسناَ',
            })
        }
        finally {
            dispatch('sharedStore/setLoaderStatus', false, { root: true })
        }
    },
}