import { httpClient } from '@/app/shared/services'
import { BaseService } from "../../shared/services/base.service";

export class AuthService extends BaseService {
    async login(phone, env) {
        try {
            const res = await httpClient.get(`${this.getBaseUrl(env)}visitor/mobiles/${phone}`)
            if(res?.status != 200) throw new Error('fail')

            return Promise.resolve(res.data.data);
        }
        catch (err) {
            return Promise.reject(err);
        }
    }
    
}