<template>
  <div class="detail-page">
    <div class="solid-inner-banner">
      <h2 class="page-title">{{details.title}}</h2>
      <ul class="page-breadcrumbs">
        <li><a href="/">الرئيسية</a></li>
        <li><i class="fa fa-angle-left" aria-hidden="true"></i></li>
        <li>{{details.title}}</li>
      </ul>
    </div> <!-- /.solid-inner-banner -->
    <div class="our-blog blog-default pt-150 mb-200">
      <div class="full-grid-container">
        <div class="single-blog-post">
          <div class="img-holder"><img :src="details.image_url" alt=""></div>
          <div class="post-data">
            <a href="#" class="date">{{details.date}}</a>
            <h5 class="blog-title-two title"><a href="blog-details-full-grid.html">{{details.short_desc}}</a></h5>
            <p>{{details.full_desc}}</p>
          </div> <!-- /.post-data -->
        </div> <!-- /.single-blog-post -->
        <!--        <div class="single-blog-post blog-text-style">-->
        <!--          <div class="post-data">-->
        <!--            <a href="#" class="date">23 July, 2018</a>-->
        <!--            <h5 class="blog-title-two title"><a href="blog-details-full-grid.html">A brand for a company is like a-->
        <!--              reputation for a person. You earn reputation by trying to do hard things well.</a></h5>-->
        <!--            <p>One touch of a red-hot stove is usually all we need to avoid that kind of discomfort in the future. The-->
        <!--              same is true as we experience the emotional sensation of stress from our firsinstances of social rejection-->
        <!--              ridicule…</p>-->
        <!--            <a href="blog-details-full-grid.html" class="read-more"><i class="flaticon-back-1"></i></a>-->
        <!--          </div> &lt;!&ndash; /.post-data &ndash;&gt;-->
        <!--        </div> &lt;!&ndash; /.single-blog-post &ndash;&gt;-->
        <!--        <div class="single-blog-post">-->
        <!--          <div class="img-holder">-->
        <!--            <img src="assets/images/blog/29.jpg" alt="">-->
        <!--            <a data-fancybox href="https://www.youtube.com/embed/aXFSJTjVjw0" class="fancybox video-button"><i-->
        <!--              class="flaticon-play-button-2"></i></a>-->
        <!--          </div>-->
        <!--          <div class="post-data">-->
        <!--            <a href="#" class="date">23 July, 2018</a>-->
        <!--            <h5 class="blog-title-two title"><a href="blog-details-full-grid.html">Challange yourself and win the-->
        <!--              future.</a></h5>-->
        <!--            <p>This response is important for our ability to learn from mistakes, but it alsogives rise to-->
        <!--              self-criticism,-->
        <!--              because it is part of the threat-protection system. In other words…</p>-->
        <!--            <a href="blog-details-full-grid.html" class="read-more"><i class="flaticon-back-1"></i></a>-->
        <!--          </div> &lt;!&ndash; /.post-data &ndash;&gt;-->
        <!--        </div> &lt;!&ndash; /.single-blog-post &ndash;&gt;-->
        <!--        <div class="single-blog-post bg-solid-post hide-pr">-->
        <!--          <div class="post-data">-->
        <!--            <h5 class="title"><a href="blog-details-full-grid.html">It is better to keep your mouth closed and let-->
        <!--              people-->
        <!--              think you are fool than to open it & remove all doubt.</a></h5>-->
        <!--            <div class="author">Jhon Ruskin</div>-->
        <!--          </div> &lt;!&ndash; /.post-data &ndash;&gt;-->
        <!--        </div> &lt;!&ndash; /.single-blog-post &ndash;&gt;-->
        <!--        <div class="single-blog-post">-->
        <!--          <div class="img-holder"><img src="images/blog/30.jpg" alt=""></div>-->
        <!--          <div class="post-data">-->
        <!--            <a href="#" class="date">23 July, 2018</a>-->
        <!--            <h5 class="blog-title-two title"><a href="blog-details-full-grid.html">Challange yourself and win the-->
        <!--              future.</a></h5>-->
        <!--            <p>This response is important for our ability to learn from mistakes, but it alsogives rise to-->
        <!--              self-criticism,-->
        <!--              because it is part of the threat-protection system. In other words…</p>-->
        <!--            <a href="blog-details-full-grid.html" class="read-more"><i class="flaticon-back-1"></i></a>-->
        <!--          </div> &lt;!&ndash; /.post-data &ndash;&gt;-->
        <!--        </div> &lt;!&ndash; /.single-blog-post &ndash;&gt;-->
      </div> <!-- /.full-grid-container -->
    </div> <!-- /.our-blog -->
  </div>
</template>

<script>
  export default {
    name: 'details-page',
    data () {
      return {}
    },
    props: {
      details: {
        type: Object
      }
    },
    created () {
    }
  }
</script>

<style scoped>

</style>
