import Vue from 'vue'
import App from './app/App.vue'
import router from './app/app-routes'
import store from './app/app-state'
import VueMeta from 'vue-meta';
import './plugins'

Vue.config.productionTip = false

Vue.use(VueMeta);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
