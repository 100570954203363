<template>
  <div class="error-creative-content">
    <div class="inner-wrapper">
      <h2>204</h2>
      <p>عذراً..  <br> المحتوى غير موجود  أو قد تم حذفه</p>
      <a href="/" class="back-button solid-button-one">العودة للرئيسية</a>
    </div>
  </div> <!-- /.error-creative-content -->
</template>
<script>
  export default {
    name: 'error-page',
  }
</script>
<style scoped>

</style>
