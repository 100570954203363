import MasterLayout from '@/layouts/MasterLayout.vue'

export default {
  name: 'dynamic-master-layout',
  created() {
    this.$parent.$emit('update:layout', MasterLayout)
  },
  render() {
    return this.$slots.default[0]
  }
}
