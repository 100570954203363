<template>
  <dynamic-master-layout>
    <WebsiteContact></WebsiteContact>
  </dynamic-master-layout>
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import WebsiteContact from '@/app/website/website-components/website-contact/website-contact'
  import * as WebsiteAPIService from '../services'

  export default {
    name: 'Faqs',
    components: {
      DynamicMasterLayout,
      WebsiteContact
    },
    created () {
    },
  }
</script>

<style scoped>

</style>
