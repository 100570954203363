<template>
    


    <div class="training-form">
        <div class="solid-inner-banner">
            <div class="container">
                <h1 class="pb-5 font-wight-bold">طلب تدريّب</h1>   
            </div>
         </div>  

         <div class="container">
            <h4 class="pt-5 ">المعلومات الشخصية</h4>  
            <div class="row mt-5">
                <div class="col">
                    <div class="contact-form">
                        <form class="form form-style-two" id="contact-form" data-toggle="validator" novalidate="true">
                            <div class="messages"></div>
                                <div class="controls row">

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="fullName" placeholder="الاسم الثلاثي*" required="required" 
                                                data-error="رجاءّ قم بادخال اسمك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="number" name="age" placeholder="العمر*" required="required" 
                                                data-error="رجاءّ قم بادخال عمرك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="birthPlace" placeholder="مكان الولادة*" required="required" 
                                                data-error="رجاءّ قم بادخال مكان ولادتك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="place" placeholder="مكان القيد*" required="required" 
                                                data-error="رجاءّ قم بادخال مكان قيدك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="nationality" placeholder="الجنسية*" required="required" 
                                                data-error="رجاءّ قم بادخال جنسيتك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="number" name="phoneNumber" placeholder="رقم الهاتف*" required="required" 
                                                data-error="رجاءّ قم بادخال رقم هاتفك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-8 px-5">
                                        <textarea type="text" name="phoneNumber" placeholder="مكان السكن*" required="required" 
                                                data-error="رجاءّ قم بادخال مكان سكنك">
                                        </textarea>
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>
                              
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         </div>   

         <div class="container">
            <h4 class="pt-5 ">المؤهلات والخبرات </h4>  
            <div class="row mt-5">
                <div class="col">
                    <div class="contact-form">
                        <form class="form form-style-two" id="contact-form" data-toggle="validator" novalidate="true">
                            <div class="messages"></div>
                                <div class="controls row">

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="fullName" placeholder="الشهادة العلمية" required="required" 
                                                data-error="رجاءّ قم بادخال اسمك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="age" placeholder="الاختصاص" required="required" 
                                                data-error="رجاءّ قم بادخال عمرك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="birthPlace" placeholder="الجهة المانحة" required="required" 
                                                data-error="رجاءّ قم بادخال مكان ولادتك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
         </div>   

         <div class="container">
            <h4 class="pt-5 ">مواد تدريبية تمتلك القدرة على إعطائها </h4>  
            <div class="row mt-5">
                <div class="col">
                    <div class="contact-form">
                        <form class="form form-style-two" id="contact-form" data-toggle="validator" novalidate="true">
                            <div class="messages"></div>
                                <div class="controls row">

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="fullName" placeholder="اسم المادة" required="required" 
                                                data-error="رجاءّ قم بادخال اسمك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group has-error has-danger col-lg-4 mb-5 px-5">
                                        <input type="text" name="age" placeholder="الساعات التدريبية" required="required" 
                                                data-error="رجاءّ قم بادخال عمرك">
                                        <div class="help-block with-errors">
                                            <ul class="list-unstyled">
                                                <!-- <li>Valid email is required.</li> -->
                                            </ul>
                                        </div>
                                    </div>
                              
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         </div>   

         <div id="theme-banner-one">
            <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
           
                   <img :src="$websiteEnvironment.assetsDir + '/website-svgs/examples/training/' + 'Group-1577.svg'" 
                        alt="" class="wow fadeInDown animated">
            </div>
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/oval-1.svg'" alt="" class="oval-one">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-1.svg'" alt="" class="shape-three">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-55.svg'" alt="" class="shape-four">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-56.svg'" alt="" class="shape-five">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-57.svg'" alt="" class="shape-six">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-58.svg'" alt="" class="shape-seven">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-59.svg'" alt="" class="shape-eight">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-60.svg'" alt="" class="shape-nine">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-61.svg'" alt="" class="shape-ten">
            <img :src="$websiteEnvironment.assetsDir + '/website-banner/shape/shape-62.svg'" alt="" class="shape-eleven">
            <div class="container">
              <div class="main-wrapper text-center">
                <a href="about-us-standard.html" class="more-button solid-button-one wow fadeInLeft  animated" 
                    data-wow-delay="1.5s" style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;">
                        تسجيل       
                </a>
              </div> 
            </div> 
          </div>

    </div>     
   
</template>

<script>
  export default {
    name: 'websiteTrainingForm',
  }
</script>

<style scoped>

.solid-inner-banner {
    background: #fafcff;
    text-align: right;
    padding: 265px 0 0;
}

#theme-banner-one .illustration {
    position: absolute;
    left: -22%;
    top: -89%;
    width: 54%;
}

</style>
