<template>
  <div id="theme-banner-four">
    <!-- <img :src="$donateEnvironment.assetsDir + '/donate-banner/img/donate.png'" style="margin-top: 100px; width: 1000px" alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s"> -->
    <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/' + 'Group-1565.svg'" style="margin-top: 100px; width: 1000px" 
          alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
    <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg'" alt="" class="shape-one">
    <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg'" alt="" class="shape-two">
    <!-- <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg'" alt="" class="shape-three"> -->
    <!-- <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg'" alt="" class="shape-four"> -->
    <div class="round-shape-one"></div>
    <!-- <div class="round-shape-two"></div> -->
    <!-- <div class="round-shape-three"></div> -->
    <!-- <div class="round-shape-four"></div> -->
    <div class="container">
      <div class="main-wrapper">
        <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
             ساهم في برامج <b > التميز</b >
             <br>
              وكن عوناً لمن هم
             <br> 
             أهلٌ للعون
        </h1>
        <p class="sub-title wow fadeInUp animated mt-5" data-wow-delay="0.9s">"والله في عون العبد ما كان العبد في عون أخيه"</p>
      </div> <!-- /.main-wrapper -->
    </div> <!-- /.container -->
  </div> <!-- /#theme-banner-four -->
</template>

<script>
  export default {}
</script>

<style scoped>

#theme-banner-four .screen-one {
    position: absolute;
    left: -3%;
    top: 12%;
    z-index: 1;
    width: 55% !important;
}

</style>
