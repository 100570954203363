import { httpClient } from '@/app/shared/services'
import { BaseService } from "../../shared/services/base.service";

export class UserService extends BaseService {
    async fetchAnalytics(keys, env) {
        try {
            const res = await httpClient.post(`${this.getBaseUrl(env)}getAnalyticsByKeys`, keys)
            if(res?.status != 200) throw new Error('fail')

            return Promise.resolve(res.data.data);
        }
        catch (err) {
            return Promise.reject(err);
        }
    }

    async sendPraies(payload, env) {
        try {
            const res = await httpClient.post(`${this.getBaseUrl(env)}analytics/increase`, payload)
            if(res?.status != 200) throw new Error('fail')

            return Promise.resolve(res.data);
        }
        catch (err) {
            return Promise.reject(err);
        }
    }
}