<template>
  <dynamic-master-layout>
    <div class="donate-home">
      <DonateBanner></DonateBanner>
      <donate-programs></donate-programs>
      <DonateWays></DonateWays>
    </div>
  </dynamic-master-layout>
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import DonateBanner from '@/app/donate/donate-components/donate-banner/donate-banner'
  import DonateWays from '@/app/donate/donate-components/donate-ways/donate-ways'
  import DonatePrograms from '@/app/donate/donate-components/donate-programs/donate-programs'

  export default {
    name: 'DonateHome',
    components: {
      DynamicMasterLayout,
      DonateBanner,
      DonateWays,
      DonatePrograms
    }
  }
</script>
