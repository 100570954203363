export default () => ({
  userLoggedin: null,
  analytics: null,
  keys: {
    keys: [
      "rehab_al_habeb_visitors",
      "rehab_al_habeb_prayers",
      "rehab_al_habeb_visitors_1443",
      "rehab_al_habeb_prayers_1443",
      "rehab_al_habeb_visitors_1444",
      "rehab_al_habeb_prayers_1444",
      "rehab_al_habeb_visitors_1445",
      "rehab_al_habeb_prayers_1445",
    ],
  },
  praiesKeys: [
    {
      key: "rehab_al_habeb_visitors_1445",
      value: 1,
      readable_key: "العدد الكلي للمشاركين في الصلوات لسنة 1445",
    },
    {
      key: "rehab_al_habeb_prayers_1445",
      value: 0,
      readable_key:
        "العدد الكلي للصلوات على النبي صلى الله عليه وسلم لسنة 1445",
    },
  ],
});
