import News from "./website-views/News.vue";
import Faqs from "./website-views/Faqs.vue";
import SuccessStories from "./website-views/SuccessStories.vue";
import ContactUs from "./website-views/Contacts.vue";
import MasterLayout from "../../layouts/MasterLayout";
import Details from "./website-views/Details.vue";
import TrainingForm from "./website-views/TrainingForm.vue";
import Error from "./website-views/Error";

const websiteRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("./website-views/auth/login.vue"),
    meta: {
      from: "rehab-alhabeb"
    }
  },
  // {
  //     path: '/login-atroja',
  //     name: 'Login',
  //     component: () => import('./website-views/auth/login.vue'),
  //     meta: {
  //         from: 'atroja'
  //     }
  // },
  {
    path: "/",
    name: "RehabAlhabeb",
    component: () => import("./website-views/rehab-alhabeb/index.vue"),
    meta: {
      requiresLogin: true,
      from: "rehab-alhabeb"
    }
  },
  {
    path: "/rehab-alhabeb",
    name: "RehabAlhabeb",
    component: () => import("./website-views/rehab-alhabeb/index.vue"),
    meta: {
      requiresLogin: true,
      from: "rehab-alhabeb"
    }
  },
  {
    path: "/praies-details",
    name: "PraiesDetails",
    component: () => import("./website-views/rehab-alhabeb/details.vue"),
    meta: {
      requiresLogin: true,
      from: "rehab-alhabeb"
    }
  },
  // {
  //     path: '/atroja',
  //     name: 'Atroja',
  //     component: () => import('./website-views/rehab-alhabeb/index.vue'),
  //     meta: {
  //         requiresLogin: true,
  //         from: 'atroja'
  //     }
  // },
  // {
  //     path: '/praies-atroja-details',
  //     name: 'PraiesDetails',
  //     component: () => import('./website-views/rehab-alhabeb/details.vue'),
  //     meta: {
  //         requiresLogin: true,
  //         from: 'atroja'
  //     }
  // },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      requiresLogin: false,
      layout: MasterLayout
    }
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
    meta: {
      requiresLogin: false,
      layout: MasterLayout
    }
  },
  {
    path: "/success-stories",
    name: "Success-stories",
    component: SuccessStories,
    meta: {
      requiresLogin: false,
      layout: MasterLayout
    }
  },
  {
    path: "/contact-us",
    name: "Contact-us",
    component: ContactUs,
    meta: {
      requiresLogin: false,
      layout: MasterLayout
    }
  },
  {
    path: "/news/:title",
    name: "News-details",
    props: route => ({ propTitle: route.params.title }),
    component: Details,
    meta: {
      requiresLogin: false,
      layout: MasterLayout
    }
  },
  {
    path: "/success-story/:title",
    name: "Success-story-details",
    props: route => ({ propTitle: route.params.title }),
    component: Details,
    meta: {
      requiresLogin: false,
      layout: MasterLayout
    }
  },
  {
    path: "/training-form",
    name: "Training-Form",
    component: TrainingForm,
    meta: {
      requiresLogin: false,
      layout: MasterLayout
    }
  },
  {
    path: "/error/204",
    name: "Error",
    component: Error
  },
  {
    path: "*",
    name: "Error",
    component: Error
  }
];

export default websiteRoutes;
