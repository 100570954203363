import Vue from 'vue'
import VueRouter from 'vue-router'
import { websiteRoutes } from './website/website-index'
import { donateRoutes } from './donate/donate-index'
import VueMeta from 'vue-meta';

Vue.use(VueRouter)
Vue.use(VueMeta)

const appRoutes = []
const routes = [...websiteRoutes, ...appRoutes, ...donateRoutes]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isRequired = to.meta.requiresLogin
  const isLoggedin = router.app.$store?.getters['userStore/isLoggedin']
  const redirectTo = to.meta.from == 'atroja' ? '/login-atroja' : '/login'
  if (isRequired && !isLoggedin) {
    next(redirectTo)
  }
  else next()
})

export default router
