<template>
    <div class="main-page-wrapper">
        <the-navbar />
        <slot/>
        <the-footer />
    </div>
</template>

<script>
import TheNavbar from '@/app/shared/components/TheNavbar.vue'
import TheFooter from '@/app/shared/components/TheFooter.vue'

export default {
    name: 'master-layout',
    components: {
        TheNavbar,
        TheFooter
    }
}
</script>
