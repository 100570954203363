import Vue from 'vue'
import Vuex from 'vuex'

//Modules
import WebsiteState from './website/website-state';
import authStore from './stores/auth';
import sharedStore from './stores/shared';
import userStore from './stores/user';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    WebsiteState,
    authStore,
    sharedStore,
    userStore,
  }
})
