<template>
  <div>
    <!-- Contact Address Two -->
    <div class="contact-address-two">
      <div class="container">
        <div class="theme-title-one text-center">
          <div class="upper-title">معلومات التواصل</div>
          <h2 class="main-title">لا تتردد بالتواصل معنا من أجل<br> أي معلومة</h2>
        </div> <!-- /.theme-title-one -->

        <div class="row">
          <div class="col-lg-4">
            <div class="address-block">
              <div class="icon-box"><img :src="$websiteEnvironment.assetsDir + '/website-contact/icon/icon59.svg'"
                                         alt=""></div>
              <h5>العنوان</h5>
              <p>دمشق - ميدان كورنيش <br>مقابل صيدلية الكواكبي</p>
            </div> <!-- /.address-block -->
          </div> <!-- /.col- -->
          <div class="col-lg-4">
            <div class="address-block">
              <div class="icon-box"><img :src="$websiteEnvironment.assetsDir + '/website-contact/icon/icon60.svg'"
                                         alt=""></div>
              <h5>الهاتف والموبايل</h5>
              <p><a href="javascript:void(0)">الهاتف: 61 - 963118833360+</a></p>
              <p><a href="javascript:void(0)">الجوال: 61 - 963948833360+</a></p>
            </div> <!-- /.address-block -->
          </div> <!-- /.col- -->
          <div class="col-lg-4">
            <div class="address-block">
              <div class="icon-box"><img :src="$websiteEnvironment.assetsDir + '/website-contact/icon/icon61.svg'"
                                         alt=""></div>
              <h5>تجدنا أيضاً</h5>
              <p>على وسائل التواصل الاجتماعي</p>
              <ul>
                <li><a href="https://www.facebook.com/Tyateem/"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </li>
                <li><a href="https://www.instagram.com/t_tamayoz/"><i class="fa fa-instagram"
                                                                      aria-hidden="true"></i></a></li>
                <li><a href="https://twitter.com/t_tamayoz"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              </ul>
            </div> <!-- /.address-block -->
          </div> <!-- /.col- -->
        </div> <!-- /.row -->
      </div> <!-- /.container -->
    </div> <!-- /.contact-address-two -->

    <!--
    =============================================
      Contact Us
    ==============================================
    -->
    <div class="contact-us-section contact-minimal">
      <div class="row no-gutters">
        <div class="col-lg-6 order-lg-last">
          <div class="inner-wrapper clearfix">
            <div class="contact-form">
              <form class="form form-style-three" id="contact-form" role="form"
                    data-toggle="validator">
                <div class="messages"></div>
                <div class="controls">
                  <div class="form-group">
                    <input id="author" type="text" name="author" placeholder="الاسم" v-model="data.author">
                  </div>

                  <div class="form-group">
                    <input id="mobile" type="number" name="mobile" placeholder="رقم الموبايل" v-model="data.mobile">
                  </div>

                  <div class="form-group">
                    <textarea id="message" name="message" class="form_message" placeholder="رسالتك"
                              v-model="data.message"
                    ></textarea>
                    <!--                    <div class="help-block with-errors"></div>-->
                  </div>
                  <button class="send-button" @click="submitFeedback">إرسال</button>
                </div> <!-- /.controls -->
              </form>
            </div> <!-- /.contact-form -->
          </div> <!-- /.inner-wrapper -->
        </div> <!-- /.col- -->
        <div class="col-lg-6 order-lg-first">
          <div id="google-map-three">
            <div class="map-canvas"></div>
          </div>
        </div>
      </div> <!-- /.row -->
    </div> <!-- /.contact-us-section -->
  </div>
</template>
<script>
  import * as WebsiteAPIService from '../../services'

  export default {
    name: 'website-contact',
    data () {
      return {
        data: {
          type: 'ContactUs',
          author: null,
          mobile: null,
          message: null
        }
      }
    },
    methods: {
      submitFeedback: function (e) {
        e.preventDefault()
        if (!this.data.message) {
          this.$swal({
            icon: 'error',
            title: 'عذراً',
            text: 'لا يمكنك ترك الرسالة فارغة، الرجاء إداخل رسالتك'
          })
          return
        }
        this.$swal({
          title: 'هل أنت متأكد؟',
          text: 'في حال المتابعة لن تستطيع التراجع',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'لا',
          confirmButtonText: 'نعم، متأكد'
        }).then((result) => {
          if (result.value) {
            WebsiteAPIService.sendFeedback(this.data).then((status) => {
              if (status === 200) {
                this.$swal(
                  'تم الإرسال بنجاح',
                  'شكراً لكم، سيتم مراجعة رسالتكم بأقرب وقت',
                  'success'
                )
              }
              else {
                this.$swal(
                  'عذراً',
                  'حدث خطأ ما، يرجى المحاولة في وقت لاحق',
                  'error'
                )
              }
            })

          }
        })
      }
    },
  }
</script>

<style scoped>

</style>
