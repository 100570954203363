<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view :layout.sync="layout"/>
    </component>
    <TestLoader />
  </div>

</template>

<script>
  import TestLoader from './shared/components/TestLoader.vue';
  export default {
    name: 'App',
    metaInfo: {
      titleTemplate: '%s | Al Tamayoz',
    },
    components: {
      TestLoader
    },
    data () {
      return {
        layout: 'div'
      }
    }
  }
</script>
