<template>
    <div>
        <Loader :isLoading="loaderStatus">
        </Loader>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Loader from './Loader.vue';

export default {
    name: 'TestLoaderComponent',
    components: {
        Loader
    },
    computed: {
        ...mapState('sharedStore', ['loaderStatus'])
    }
}
</script>