<template>
  <DynamicMasterLayout>
    <WebsiteDetailPage v-bind:details="details"></WebsiteDetailPage>
  </DynamicMasterLayout>
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import WebsiteDetailPage from '@/app/shared/components/details-page'
  import * as WebsiteAPIService from '../services'

  export default {
    name: 'Details',
    components: {
      DynamicMasterLayout,
      WebsiteDetailPage
    },
    data () {
      return {
        details: {}
      }
    },
    props: {
      propTitle: String
    },
    created () {
      WebsiteAPIService.getBlogByTitle(this.$stringCase.normalCase(this.propTitle)).then((data) => {
        this.details = data
      })
    },
  }

</script>

<style scoped>

</style>
