<template>
  <!-- =============================================
                    Theme Main Menu
    ============================================== -->
  <div class="theme-main-menu theme-menu-one">
    <div class="logo"><a href="/"><img src="assets/images/logo/tamayoz-logo-80.png" alt="tamayoz-logo"></a>
    </div>
    <nav id="mega-menu-holder" class="navbar navbar-expand-lg" style="margin-right: -80px">
      <div class="container nav-container">
        <!-- <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
          <i class="flaticon-setup"></i>
        </button> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <!-- <li class="nav-item active dropdown">
              <a href="/" class="nav-link">الرئيسية</a>
            </li> -->
            <!-- <li class="nav-item dropdown position-relative">
              <a class="nav-link" href="/donate">برامج العطاء</a>
            </li> -->
            <!-- <li class="nav-item dropdown position-relative">
              <a class="nav-link" href="/success-stories">قصص النجاح</a>
            </li> -->
            <!-- <li class="nav-item dropdown">
              <a class="nav-link" href="#">الأنشطة القادمة</a>
            </li> -->
            <!-- <li class="nav-item dropdown">
              <a class="nav-link" href="/faqs">سؤال و جواب</a>
            </li> -->
            <!-- <li class="nav-item dropdown position-relative">
              <a class="nav-link" href="/news">الأخبار</a>
            </li> -->
            <!-- <li class="nav-item dropdown position-relative">
              <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">النماذج</a>
              <ul class="dropdown-menu">
                <li><a href="blog-full-grid.html" class="dropdown-item">عام</a></li>
                <li><a href="/training-form" class="dropdown-item">تدريس</a></li>
                <li><a href="blog-filter.html" class="dropdown-item">تدريب</a></li>
                <li><a href="blog-full-grid.html" class="dropdown-item">تطوع</a></li>
              </ul>   
            </li> -->
            <!-- <li class="nav-item dropdown position-relative">
              <a class="nav-link" href="/rehab-alhabeb">رحاب الحبيب</a>
            </li> -->
          </ul>
        </div>
      </div> <!-- /.container -->
    </nav> <!-- /#mega-menu-holder -->
    <!-- <div class="header-right-widget">
      <ul>
        <li class="call-us"> اتصل بنا<a href="#">9904 - 96311 +</a></li>
        <li class="language-switcher">
          <div class="m-l-100"></div>
       
        </li>
        <li><a href="/contact-us" class="contact-us white-shdw-button">تواصل معنا <i
          class="icon flaticon-back"></i></a></li>
      </ul>
    </div>  -->
  </div> <!-- /.theme-main-menu -->
</template>

<script>
  export default {
    name: 'the-navbar'
  }
</script>
