<template>
  <div>
    <dynamic-master-layout>
      <websiteTrainingForm></websiteTrainingForm>
    </dynamic-master-layout>
  </div>
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import websiteTrainingForm from '@/app/website/website-components/website-forms-pages/website-training-form.vue'

  export default {
    name: 'TrainingForm',
    components: {
      DynamicMasterLayout,
        websiteTrainingForm
    },
  }


</script>

<style scoped>

</style>
