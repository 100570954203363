<template>
  <div>
    <dynamic-master-layout>
      <WebsiteSuccessStoriesBanner></WebsiteSuccessStoriesBanner>
    </dynamic-master-layout>
    <WebsiteSuccessStories></WebsiteSuccessStories>
  </div>
  
</template>

<script>
  import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
  import WebsiteSuccessStories from '@/app/website/website-components/website-success-stories/website-success-stories'
  import WebsiteSuccessStoriesBanner from '@/app/website/website-components/website-success-stories-banner/website-success-stories-banner'

  export default {
    name: 'SuccessStories',
    components: {
      DynamicMasterLayout,
      WebsiteSuccessStories,
      WebsiteSuccessStoriesBanner
    },
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
    }
  }
</script>

<style scoped>

</style>
