import {httpClient} from '../../shared/services'
import {environment} from '../website-environment'

// Home page
const fetchHomePage = () => {
    const url = environment.websiteApiBaseUrl + 'homepage'
    var input = {
        feedbackType: 'Feedback'
    }
    return httpClient.post(url, input)
        .then(res => res.data['data'])
        .catch(error => console.log(error))
}

// Blog //
const fetchBlogByType = (blogType, withPaginate = false) => {
    const url = environment.websiteApiBaseUrl + 'blog/type/' + blogType + '/withPaginate/' + withPaginate

    return httpClient.get(url)
        .then(res => res.data['data'])
        .catch(error => console.log(error))
}

// get blog by title
const getBlogByTitle = (title) => {
    const url = environment.websiteApiBaseUrl + 'blog/' + title
    return httpClient.get(url)
        .then(res => res.data['data'])
        .catch(error => console.log(error))
}

const sendFeedback = (message) => {
    const url = environment.websiteApiBaseUrl + 'feedback'
    var input = message

    return httpClient.post(url, input)
        .then(res => res.status)
        .catch(error => console.log(error))
}

const paginate = (url) => {
    return httpClient.get(url)
        .then(res => res.data['data'])
        .catch(error => console.log(error))
}

const fetchRehabAlhabebAnalytics = (keys, env = 'prod') => {
    let url = (env === 'dev') ? environment.websiteApiBaseUrlStaging + 'getAnalyticsByKeys' : environment.websiteApiBaseUrl + 'getAnalyticsByKeys';
    return httpClient.post(url, keys)
        .then(res => res.data['data'])
        .catch(error => console.log(error))
}

const sendPrays = (analytics, env = 'prod') => {
    let url = (env === 'dev') ? environment.websiteApiBaseUrlStaging + 'analytics/increase' : environment.websiteApiBaseUrl + 'analytics/increase';
    return httpClient.post(url, analytics)
        .then(res => res.data)
        .catch(error => console.log(error))
}

const findUserByPhone = (phoneNumber, env = 'prod') => {
    let url = (env === 'dev') ? environment.websiteApiBaseUrlStaging + 'visitor/mobiles' : environment.websiteApiBaseUrl + 'visitor/mobiles';
    return httpClient.get(url + '/' + phoneNumber)
        .then(res => res)
        .catch(error => console.log(error))
}

export {
    fetchHomePage, fetchBlogByType, sendFeedback, paginate, getBlogByTitle, fetchRehabAlhabebAnalytics, sendPrays, findUserByPhone
}
